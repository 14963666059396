<template>
  <v-card class="mt-3">
    <v-card-title>
      <v-col class="col-4">
        Service Type:
      </v-col>
      <v-col>
        <v-select
          v-model="selectedType"
          label="Select Service Type"
          :items="setServiceTypes"
          :value="setServiceTypes.text"
          return-object
          :disabled="!isGlobal"
          @change="changeSelectedType()"
        />
      </v-col>
    </v-card-title>

    <v-card-text>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div v-if="selectedType.value">
        <v-stepper v-model="e1">
          <v-stepper-header v-if="selectedType.value === 'WS'">
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              color="color_green"
            >
              Select service
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              color="color_green"
            >
              Select layers
            </v-stepper-step>

            <v-divider v-if="isGlobal" />

            <v-stepper-step
              v-if="isGlobal"
              :complete="e1 > 3"
              step="3"
              color="color_green"
            >
              Global Layers
            </v-stepper-step>

            <v-divider v-if="isGlobal" />

            <v-stepper-step
              v-if="isGlobal"
              step="4"
              color="color_green"
            >
              Customer Layers
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-data-table
                v-model="selectedService"
                :headers="servicesHeaders"
                :items="selectedType.value === 'WS' ? filteredServices.filter(item => item.ws_group === selectedWsGroup) : filteredServices"
                show-select
                :single-select="selectedType.value !== 'WS'"
                class="elevation-0"
                item-key="service_id"
                @input="limiter"
                @toggle-select-all="selectedService = []"
              >
                <template v-slot:top>
                  <v-toolbar
                    v-if="selectedType.value === 'WS'"
                    class="mt-5"
                    flat
                  >
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%">
                      <v-select
                        v-model="selectedWsGroup"
                        :items="wsGroups"
                        :disabled="!isGlobal"
                        label="Workspace Group"
                        return-object
                        solo
                      />
                      <v-spacer />
                    </div>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.service_name`]="{ item }">
                  {{ item.service_name }}
                  <small
                    v-if="item.layer_type"
                    class="text--disabled"
                  >
                    - {{ item.layer_type }}
                  </small>
                </template>
                <template
                  v-if="alertMessage !== ''"
                  v-slot:footer
                >
                  <v-alert
                    dense
                    outlined
                    type="error"
                  >
                    {{ alertMessage }}
                  </v-alert>
                </template>
                <template v-slot:no-data>
                  <span color="primary">No {{ selectedType.text }} services found. Please link a policy to all users of this customer. </span>
                </template>
              </v-data-table>
              <div class="d-flex justify-space-between">
                <v-btn
                  dark
                  color="warning"
                  @click="close()"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="selectedType.value === 'WS'"
                  color="color_green"
                  :dark="selectedService.filter(item => item.ws_group === selectedWsGroup).length > 0"
                  :disabled="selectedService.filter(item => item.ws_group === selectedWsGroup).length < 1"
                  @click="setServiceLayerTable()"
                >
                  Continue
                </v-btn>
                <v-btn
                  v-else
                  color="color_green"
                  :dark="!confirmIsDisabled"
                  :disabled="confirmIsDisabled"
                  @click="confirm()"
                >
                  Confirm
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table
                :headers="serviceLayersHeaders"
                :items="configServices"
                item-key="service_id"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-select
                    v-model="selectedServiceLayer[item.service_id]"
                    label="Select Layer"
                    :items="item.layers"
                    class="my-auto"
                    color="green"
                    hide-details
                    clearable
                    dense
                    solo
                  />
                </template>
                <template v-slot:[`item.is_meteogram`]="{ item }">
                  <v-checkbox
                    v-if="renderCheckbox"
                    v-model="selectedServiceMeteogram[item.service_id]"
                    :disabled="!item.is_meteogram"
                    color="green"
                    class="mx-5"
                    @change="singleMeteogramSelect(item.service_id)"
                  />
                </template>
              </v-data-table>
              <div class="d-flex justify-space-between">
                <v-btn
                  dark
                  color="warning"
                  @click="close()"
                >
                  Close
                </v-btn>
                <div>
                  <v-btn
                    text
                    @click="e1 = 1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    v-if="isGlobal"
                    dark
                    color="color_green"
                    @click="setGlobalLayerTable()"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    v-else
                    color="color_green"
                    dark
                    @click="confirm()"
                  >
                    Confirm
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-data-table
                v-model="selectedGlobalLayers"
                :headers="layersHeaders"
                :items="globalLayers"
                item-key="global_layer_id"
                dense
                show-select
              />
              <div class="d-flex justify-space-between">
                <v-btn
                  dark
                  color="warning"
                  @click="close()"
                >
                  Close
                </v-btn>
                <div>
                  <v-btn
                    text
                    @click="e1 = 2"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="color_green"
                    dark
                    @click="setCustomerLayerTable()"
                  >
                    Continue
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-data-table
                v-model="selectedCustomerLayers"
                :headers="layersHeaders"
                :items="layers.customerLayers"
                item-key="global_layer_id"
                dense
                show-select
              />
              <div class="d-flex justify-space-between">
                <v-btn
                  dark
                  color="warning"
                  @click="close()"
                >
                  Close
                </v-btn>
                <div>
                  <v-btn
                    text
                    @click="e1 = 3"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="color_green"
                    dark
                    @click="confirm()"
                  >
                    Confirm
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SERVICE_TYPES } from '@/constants.js';

export default {
  props: {
    appScope: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      e1: 1,
      overlay: false,
      renderCheckbox: true,
      alertMessage: '',
      selectedType: {},
      configServices: [],
      selectedService: [],
      selectedGlobalLayers: [],
      selectedCustomerLayers: [],
      filteredServices: [],
      globalLayers: [],
      selectedServiceLayer: {},
      selectedServiceMeteogram: {},
      selectedWsGroup: 'Archive',
      wsGroups: ['Archive', 'Real-Time', 'Forecast'],
      servicesHeaders: [
        {
          text: 'Service Name',
          align: 'start',
          sortable: true,
          value: 'service_name',
          width: '35%'
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description'
        }
      ],
      layersHeaders: [
        {
          text: 'Layer Name',
          value: 'layer_name',
          width: '35%'
        },
        {
          text: 'Layer Description',
          value: 'layer_description'
        }
      ],
      serviceLayersHeaders: [
        {
          text: 'Service Name',
          align: 'start',
          sortable: true,
          value: 'service_name',
          width: '35%'
        },
        {
          text: 'Layer type',
          value: 'layer_type',
          width: '15%'
        },
        {
          text: 'Select Layer',
          value: 'actions'
        },
        {
          text: 'Meteogram',
          value: 'is_meteogram',
          align: 'center'
        }
      ]
    };
  },
  computed: {
    ...mapState(['customer', 'layers', 'policy']),
    setServiceTypes() {
      return SERVICE_TYPES;
    },
    confirmIsDisabled() {
      if (!this.selectedType.value) {
        return true;
      } else {
        // if (this.selectedType.value === 'WS') {
        //   return this.e1 !== 3;
        // } else {
        return this.selectedService.length === 0;
        // }
      }
    },
    scope() {
      return this.appScope.replace('-', '');
    },
    isGlobal() {
      return this.appScope === 'GLOBAL';
    }
  },
  mounted() {
    if (!this.isGlobal) {
      this.selectedType = { text: 'Workspace', value: 'WS' };
      this.selectedWsGroup = this.appScope;
      this.changeSelectedType();
    }

    if (this.customer.defaultService[this.scope].service_type !== '') {
      this.selectedType = this.setServiceTypes.filter(item => item.value === this.customer.defaultService[this.scope].service_type)[0];
      this.changeSelectedType();

      if (this.customer.defaultService[this.scope].service_type === 'WS') {
        this.selectedWsGroup = this.customer.defaultService[this.scope].service_path.replace('map/', '');
        this.filteredServices.filter(item => item.ws_group === this.customer.defaultService[this.scope].service_path.replace('map/', ''));
        this.customer.defaultService[this.scope].service_details.forEach(item => {
          this.selectedServiceLayer[item.service_id] = item.layer_name;
          this.selectedServiceMeteogram[item.service_id] = item.is_meteogram;
        });
      }

      this.customer.defaultService[this.scope].service_details.forEach(item => {
        const obj = this.filteredServices.find(service => service.service_id === item.service_id);
        this.selectedService.push(obj);
      });
    }
  },
  methods: {
    ...mapActions(['storeConfigurationWorkspacePolicy', 'storeCustomerLayers', 'createDefaultServiceAction', 'updateDefaultServiceAction', 'storeDefaultService', 'deleteDefaultServiceAction']),
    singleMeteogramSelect(service_id) {
      for (const e in this.selectedServiceMeteogram) {
        if (e !== service_id) {
          this.selectedServiceMeteogram[e] = false;
        }
      }

      this.renderCheckbox = false;
      this.$nextTick(() => {
        this.renderCheckbox = true;
      });
    },
    limiter(e) {
      const filtered = e.filter(item => item.ws_group === this.selectedWsGroup);

      if (filtered.filter(item => item.layer_type === 'RASTER').length > 1) {
        this.alertMessage = 'Only 1 Raster type allowed.';
        e.pop();
      }

      if (filtered.length > 5) {
        this.alertMessage = 'A maximum of 5 services can be selected.';
        e.pop();
      }

      setTimeout(() => this.alertMessage = '', 5000);
    },
    changeSelectedType() {
      this.overlay = true;
      this.e1 = 1;
      this.configServices = [];
      this.selectedService = [];
      this.selectedGlobalLayers = [];
      this.selectedCustomerLayers = [];
      this.globalLayers = [];
      this.selectedServiceLayer = {};
      this.selectedServiceMeteogram = {};
      this.filteredServices = this.customer.customerCommonServices.filter(item => item.service_type === this.selectedType.value);
      this.overlay = false;
    },
    async setServiceLayerTable() {
      this.configServices = [];
      this.overlay = true;
      const promises = [];
      this.selectedService = this.selectedService.filter(item => item.ws_group === this.selectedWsGroup);
      this.selectedService.forEach(item => {
        const data = {
          pws_id: item.pws_id,
          service_id: item.service_id,
          policy_id: item.policy_id

        };
        promises.push(data);
      });
      await this.storeConfigurationWorkspacePolicy(promises);
      this.policy.wsConfigurationPolicyArray.forEach(item => {
        const layers = [];
        item.layers.forEach(layer => {
          if (!layer.layer_name.includes('ignore')) {
            const layer_name = layer.layer_name;
            const ws_name = item.ws_name.filter(ws => ws.ws_id === layer.ws_id)[0].ws_name;
            layers.push(`${ws_name}:${layer_name}`);
          }
        });
        const filtered = this.selectedService.filter(service => service.policy_id === item.policy_id && service.service_id === item.service_id);
        const serviceObj = {
          service_name: filtered[0].service_name,
          layer_type: filtered[0].layer_type,
          service_id: item.service_id,
          policy_id: item.policy_id,
          is_meteogram: item.is_meteogram,
          layers: layers
        };
        this.configServices.push(serviceObj);
      });

      Object.keys(this.selectedServiceLayer).forEach(property => {
        if (!this.configServices.some(item => item.service_id === property)) {
          delete this.selectedServiceLayer[property];
          delete this.selectedServiceMeteogram[property];
        }
      });
      this.e1 = 2;
      this.overlay = false;
    },
    setGlobalLayerTable() {
      this.selectedService.forEach(item => {
        item.global_layers.forEach(layer => {
          if (!this.globalLayers.find(gLayer => gLayer.global_layer_id === layer.global_layer_id)) {
            this.globalLayers.push(layer);
          }
        });
      });

      if (this.customer.defaultService[this.scope].default_id !== '') {
        this.customer.defaultService[this.scope].global_layer_id.forEach(item => {
          const obj = this.globalLayers.find(gl => gl.global_layer_id === item.id);

          if (obj) {
            this.selectedGlobalLayers.push(obj);
          }
        });
      }

      this.e1 = 3;
    },
    async setCustomerLayerTable() {
      this.overlay = true;
      await this.storeCustomerLayers(this.customer.crm_id);

      if (this.customer.defaultService[this.scope].default_id !== '') {
        this.customer.defaultService[this.scope].customer_layer_id.forEach(item => {
          const obj = this.layers.customerLayers.find(cl => cl.global_layer_id === item.id);

          if (obj) {
            this.selectedCustomerLayers.push(obj);
          }
        });
      }

      this.e1 = 4;
      this.overlay = false;
    },
    async confirm() {
      this.overlay = true;
      const data = {
        customer_id: this.customer.crm_id,
        service_type: this.selectedService[0].service_type,
        ws_group: this.selectedService[0].ws_group,
        service_details: [],
        global_layer_id: [],
        customer_layer_id: []
      };
      this.selectedService.forEach(service => {
        data.service_details.push({
          service_id: service.service_id,
          layer_name: this.selectedServiceLayer[service.service_id],
          is_meteogram: this.selectedServiceMeteogram[service.service_id] || false
        });
      });
      this.selectedGlobalLayers.forEach(item => data.global_layer_id.push(item.global_layer_id));
      this.selectedCustomerLayers.forEach(item => data.customer_layer_id.push(item.global_layer_id));

      if (this.customer.defaultService[this.scope].default_id !== '') {
        data.default_id = this.customer.defaultService[this.scope].default_id;
        await this.updateDefaultServiceAction(data);
      } else {
        data.app_scope = this.appScope;
        await this.createDefaultServiceAction(data);
      }

      this.close();
      await this.storeDefaultService({ customer_id: this.customer.crm_id, app_scope: this.appScope });
      this.overlay = false;
    },
    close() {
      this.$emit('close-config');
    }
  }
};
</script>
